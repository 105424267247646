import React from "react";
import { Route as ReactRouterDomRoute } from "react-router-dom";

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
    return (
        <ReactRouterDomRoute
            render={() => {
                return <Component />
            }}
            {...rest}
        />
    );
};

export default Route;
