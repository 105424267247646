import styled from "styled-components";

export const Container = styled.main`
    margin: 0 auto;
    max-width: 1440px;

    max-height: 100vh;
    height: 100vh;

    display: flex;
    flex-direction: column;

    @media (max-width: 1280px) {
        max-width: 1280px;
    }
    @media (max-width: 1024px) {
        max-width: 992px;
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 6rem;

    @media (max-width: 1280px) {
        padding: 2rem 3rem;
    }
    @media (max-width: 1024px) {
        padding: 2rem 2rem;
    }
`;

export const Middle = styled.div`
    flex: 1;
    display: flex;
`;

export const FraseSection = styled.section`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;

    padding: 0 0 0 6rem;

    @media (max-width: 1280px) {
        padding: 0 0 0 3rem;
    }
    @media (max-width: 1024px) {
        padding: 0 0 0 2rem;
    }
`;

export const Title = styled.h1`
    font-size: 4.5rem;
    line-height: 0.8;

    @media (max-width: 1280px) {
        font-size: 5rem;
    }
    @media (max-width: 1024px) {
        font-size: 4rem;
    }
`;

export const Subtitle = styled.h2`
    font-size: 1.75rem;
    max-width: 80%;
    font-weight: normal;
    color: var(--white);

    @media (max-width: 1280px) {
        font-size: 1.25rem;
    }
    @media (max-width: 1024px) {
        font-size: 1.125rem;
        max-width: 80%;
    }
`;

export const DownloadTitle = styled.h3`
    font-size: 1.75rem;
    max-width: 70%;
    font-weight: normal;
    color: var(--gray-300);

    @media (max-width: 1280px) {
        font-size: 1.25rem;
    }
    @media (max-width: 1024px) {
        font-size: 1.125rem;
        max-width: 80%;
    }
`;

export const DownloadSection = styled.div`
    display: flex;
    align-items: center;

    gap: 1rem;

    @media (max-width: 1024px) {
        button {
            font-size: 0.75rem;
        }
    }
`;

export const ImageSection = styled.section`
    flex: 1;

    padding: 0 6rem 0 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
        width: 110%;
    }

    @media (max-width: 1512px) {
        img {
            width: 100%;
        }
    }
    @media (max-width: 1280px) {
        padding: 0 3rem 0 0;

        img {
            width: 110%;
        }
    }
    @media (max-width: 1024px) {
        padding: 0 2rem 0 0;

        img {
            width: 75%;
        }
    }
`;


export const Footer = styled.footer`
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    border-top: 0.25px solid var(--gray-400);

    @media (max-width: 1024px) {
        padding: 1rem 0 2rem 0;
    }
`;
