import React, { useState } from "react";

import { Container, FormContainer } from "./styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ReactComponent as Logo } from "../../../assets/img/OiCarLogoDarkLogin.svg";
import { Typography } from "@mui/material";
import { sendSupport } from "../../../service/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DataRequest = () => {
    const navigate = useHistory(); 
    const [inputs, setInputs] = useState({
        email: "",
        description: "",
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            isDataRequest: true,
            email: inputs.email,
            description: inputs.description
        }

        try {
            await sendSupport(body);
            alert("Solicitação de dados enviada!");
            setInputs({
                email: "",
                description: ""
            });
            navigate.push('/'); 
        } catch (error) {
            alert("Erro ao enviar solicitação: " + error.message);
        }
    };

    return (
        <Container>
            <FormContainer>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        direction="column"
                        rowSpacing={2}
                        justifyContent="center"
                        alignContent="center"
                        marginTop={3}
                    >
                        <Grid item display="grid" justifyItems="center" gap={3} marginBottom={3}>
                            <Logo style={{ width: "150px", height: "50px" }} />
                            <Typography fontSize={20} fontWeight="bold">LGPD OiCar</Typography>
                            <Typography fontSize={18} maxWidth={300}>Solicite o envio ou a exlusão dos seus dados do sistema OiCar pelo formulário abaixo, 
                            responderemos com a demanda via email em até 2 dias úteis.</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                name="email"
                                type="email"
                                required
                                value={inputs.email}
                                label="Email"
                                variant="outlined"
                                onChange={handleChange}
                                style={{width: 300}}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="description"
                                required
                                value={inputs.description}
                                label="Motivo"
                                type="text"
                                variant="outlined"     
                                multiline
                                rows={6}     
                                onChange={handleChange}
                                style={{width: 300}}
                            />
                        </Grid>
                        <Grid item marginTop={3}>
                            <Button variant="contained" type="submit" style={{ width: 300, height: 50 }}>
                                Enviar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </Container>
    );
};

export default DataRequest;
