import { useEffect, useState } from "react";
import { Web } from "./components/Web";
import { Phone } from "./components/Phone";

const BREAKPOINTS = {
    monitor: 992,
};

const TEMPLATES = {
    phone: <Phone />,
    monitor: <Web />,
};

function getWindowWidth() {
    const { innerWidth } = window;
    return innerWidth;
}

export default function App() {
    const [w, setW] = useState(getWindowWidth());
    console.log(w);

    useEffect(() => {
        function handleWindowResize() {
            setW(getWindowWidth());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    let template;

    if (w < BREAKPOINTS.monitor) {
        template = TEMPLATES.phone;
    } else if (w > BREAKPOINTS.monitor) {
        template = TEMPLATES.monitor;
    }

    return template;
}
