import React from "react";
import { Switch } from "react-router-dom";
import App from "../App";
import Route from "./Route";
import Support from "../components/Web/Support";
import DataRequest from "../components/Web/DataRequest";

const MainRoutes = () => {
    return (
        <Switch>
            <Route path="/support" exact component={Support} />
            <Route path="/data-request" exact component={DataRequest} />
            <Route path="/*" component={App} />
        </Switch>
    );
};

export default MainRoutes;
