import { Button } from "@mui/material";

import OiCarBanner from "../../assets/img/handWithOiCar.png";
import OiCarLogo from "../../assets/img/oicar-logo.png";
import PlayStoreLogo from "../../assets/img/playStore.png";
import AppStoreLogo from "../../assets/img/appStore.png";

import {
    Container,
    Header,
    Middle,
    FraseSection,
    Title,
    Subtitle,
    DownloadSection,
    DownloadTitle,
    ImageSection,
    Footer,
} from "./style";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export function Web() {
    const history = useHistory()
    return (
        <Container>
            <Header>
                <img height={60} src={OiCarLogo} alt="logotipo da OiCar" />

                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        backgroundColor: "var(--brand-yellow)",
                        color: "var(--black)",
                        height: 54,
                        width: 200,
                        fontWeight: "bold",
                        boxShadow: "none",
                    }}
                    href="https://api.whatsapp.com/send?1=pt_BR&phone=555120420124"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    FALE COM A OiCar
                </Button>
                
            </Header>
            <Middle>
                <FraseSection>
                    <Title>Seu veículo no bolso</Title>

                    <Subtitle>
                        Cadastre seu veículo e{" "}
                        <b style={{ color: "var(--brand-yellow)" }}>tenha o controle real dos seus gastos</b>, 100%
                        automatizado.
                    </Subtitle>

                    <DownloadTitle>Faça o download do app:</DownloadTitle>

                    <DownloadSection>
                        <Button
                            variant="contained"
                            startIcon={
                                <img
                                    src={PlayStoreLogo}
                                    style={{ width: "30px", height: "30px" }}
                                    alt="logo da play store"
                                />
                            }
                            style={{
                                backgroundColor: "var(--black)",
                                color: "var(--white)",
                                height: 54,
                                width: 264,
                                fontWeight: "bold",
                                borderRadius: "50px",
                                gap: "0.25rem",
                                boxShadow: "none",
                            }}
                            // onClick={handleClick}
                            // aria-describedby={id}
                            href="https://play.google.com/store/apps/details?id=br.com.oficina.oicar"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Play Store
                        </Button>

                        <Button
                            variant="contained"
                            startIcon={
                                <img
                                    src={AppStoreLogo}
                                    style={{ width: "30px", height: "30px" }}
                                    alt="logo da app store"
                                />
                            }
                            style={{
                                backgroundColor: "var(--black)",
                                color: "var(--white)",
                                height: 54,
                                width: 264,
                                fontWeight: "bold",
                                borderRadius: "50px",
                                gap: "0.25rem",
                                boxShadow: "none",
                            }}
                            // onClick={handleClick}
                            // aria-describedby={id}
                            href="https://apps.apple.com/br/app/oicar-seu-ve%C3%ADculo-no-bolso/id1668148724"
                            target="_blank"
                            rel="noreferrer"
                        >
                            App Store
                        </Button>
                    </DownloadSection>
                </FraseSection>
                <ImageSection>
                    <img src={OiCarBanner} alt="imagem de uma mão segurando aplicativo OiCar" />
                </ImageSection>
            </Middle>
            <Footer>
                <Button 
                    style={{
                        color: "var(--white)",
                        height: 15,
                        width: 150,
                        fontWeight: "bold",
                        boxShadow: "none",
                    }}
                    onClick={() => history.push("/support")}>
                    Suporte
                </Button>
                <Button 
                    style={{
                        color: "var(--white)",
                        height: 10,
                        width: 150,
                        fontWeight: "bold",
                        boxShadow: "none",
                    }}
                    onClick={() => history.push("/data-request")}>
                    LGPD
                </Button>
            </Footer>
        </Container>
    );
}
