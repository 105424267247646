// src/api.js
import axios from 'axios';

const client = axios.create({
    baseURL: "https://api.oicar.com.br/api", // Replace with your API base URL
});

export const sendSupport = async (body) => {
    try {
        const response = await client.post('/support/save/external', body);
        return response.data;
    } catch (error) {
        throw error;
    }
};
