import styled from "styled-components";

export const Container = styled.main`
    width: 100%;

    max-height: 100vh;
    height: 100vh;

    display: flex;
    flex-direction: column;

    padding: 0.675rem;
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    position: relative;
    padding: 1rem 2rem;
`;

export const Middle = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const ImageSection = styled.section`
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
        width: 95%;
    }
`;

export const FraseSection = styled.section`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.75rem;

    padding: 1rem 0.25rem;
`;

export const Title = styled.h1`
    font-size: 3rem;
    line-height: 0.8;
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    max-width: 90%;
    font-weight: normal;
    color: var(--white);
`;

export const DownloadTitle = styled.h2`
    font-size: 1rem;
    max-width: 80%;
    font-weight: normal;
    color: var(--gray-300);
`;

export const DownloadSection = styled.div`
    display: flex;

    gap: 0.75rem;

    div {
        display: flex;
        gap: 0.75rem;
    }
`;
